<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>지원서 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value selected disabled>선택</option>
          <option value="providerName">제목</option>
          <option value="type">타입</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one planner">
      <h2 class="bold">
        교육 목록
        <button class="right basic">
          <router-link to="/admin/lecture/register">강의 등록</router-link>
        </button>
      </h2>

      <table>
        <colgroup>
          <col style="width: 7%" />
          <col style="width: 20%" />
          <col style="width: 13%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 15%" />
          <col style="width: 5%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>모집명</th>
          <th>상태</th>
          <th>접수날짜</th>
          <th>교육날짜</th>
          <th>등록일</th>
          <th></th>
        </tr>
        <tr v-for="(data, i) in list" :key="i">
          <td>
            <router-link to @click.native="handleMove(data)">{{
              total + 1 - (i + 1 + (currentPage - 1) * 10)
            }}</router-link>
          </td>
          <td>
            <router-link to @click.native="handleMove(data)">{{
              data.title
            }}</router-link>
          </td>

          <td>
            {{
              moment(data.startRegistration) > moment(new Date())
                ? "접수대기"
                : moment(new Date()) > moment(data.endRegistration)
                ? "접수마감"
                : "접수중"
            }}
          </td>
          <td>
            {{ moment(data.startRegistration).format("YYYY.MM.DD") }} -
            {{ moment(data.endRegistration).format("YYYY.MM.DD") }}
          </td>
          <td>
            {{ moment(data.startDate).format("YYYY.MM.DD") }} -
            {{ moment(data.endDate).format("YYYY.MM.DD") }}
          </td>

          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td>
            <router-link to @click.native="handleUpdate(data)"
              >수정</router-link
            >
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="list.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchLectureList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "applicationManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      status: "",
      list: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    this.getLecturerList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.status = "";
      this.currentPage = 1;
      this.list = [];
      this.total = 0;
      this.getLecturerList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getLecturerList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getLecturerList();
    },
    handleMove(obj) {
      this.$router.push({
        name: "applications",
        query: { id: obj._id, title: obj.title },
      });
    },
    handleUpdate(obj) {
      console.log("obj", obj);
      this.$router.push({ name: "lectureRegister", query: { id: obj._id } });
    },

    getLecturerList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: this.status,
      };
      fetchLectureList(params).then((res) => {
        if (res.data.status == 200) {
          this.list = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
